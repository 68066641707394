body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 地図部分のシャドー設定 */
.map-item-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

/* ストリートビューの全画面表示ボタン削除 */
.gm-fullscreen-control {
  display: none;
}

/* ポリゴンの作成・編集時のトーストメッセージ関連 */
#toast {
  position: absolute;
  top: 80px;
  left: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  z-index: 3000;
  background-color: rgb(255, 230, 230);
}

.showToast {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
}

.hideToast {
  opacity: 0;
  visibility: hidden;
  transition: 1s;
}

#toast > p {
  font-size: 18px;
  color: #e60012;
}

/* インフォウィンドウ内の閉じるボタン */
.close-button {
  position: absolute;
  top: 1px;
  right: 2px;
  cursor: pointer;
  font-size: 20px;
  background: #fff;
  border: none;
  outline: none;
}

/* 既存店のインフォウィンドウ内の任意商圏関連ボタン */
.market-area-button {
  display: none;
  width: 100%;
  margin: 3px 0px;
  background-color: #00cc5b;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

/* 既存店のインフォウィンドウ内の任意商圏関連ボタン */
.market-area-delete-button {
  display: none;
  width: 100%;
  margin: 3px 0px;
  background-color: #e60012;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

/* カレンダーの天気情報のスタイル */
.calendarItem {
  zoom: 0.15;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 80px;
  overflow: hidden;
  pointer-events: none;
}

.item {
  zoom: 0.3;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 80px;
  overflow: hidden;
  pointer-events: none;
}

/* カレンダーの天気アイコンのスタイル */
.item100 {
  position: absolute;
  max-width: initial;
  left: -0px;
  top: -0px;
}
.item101 {
  position: absolute;
  max-width: initial;
  left: -150px;
  top: -0px;
}
.item102 {
  position: absolute;
  max-width: initial;
  left: -300px;
  top: -0px;
}
.item104 {
  position: absolute;
  max-width: initial;
  left: -450px;
  top: -0px;
}
.item110 {
  position: absolute;
  max-width: initial;
  left: -600px;
  top: -0px;
}
.item112 {
  position: absolute;
  max-width: initial;
  left: 0px;
  top: -80px;
}
.item115 {
  position: absolute;
  max-width: initial;
  left: -150px;
  top: -80px;
}
.item200 {
  position: absolute;
  max-width: initial;
  left: -300px;
  top: -80px;
}
.item201 {
  position: absolute;
  max-width: initial;
  left: -450px;
  top: -80px;
}
.item202 {
  position: absolute;
  max-width: initial;
  left: -600px;
  top: -80px;
}
.item204 {
  position: absolute;
  max-width: initial;
  left: 0px;
  top: -160px;
}
.item210 {
  position: absolute;
  max-width: initial;
  left: -150px;
  top: -160px;
}
.item212 {
  position: absolute;
  max-width: initial;
  left: -300px;
  top: -160px;
}
.item215 {
  position: absolute;
  max-width: initial;
  left: -450px;
  top: -160px;
}
.item300 {
  position: absolute;
  max-width: initial;
  left: -600px;
  top: -160px;
}
.item301 {
  position: absolute;
  max-width: initial;
  left: 0px;
  top: -240px;
}
.item302 {
  position: absolute;
  max-width: initial;
  left: -150px;
  top: -240px;
}
.item303 {
  position: absolute;
  max-width: initial;
  left: -300px;
  top: -240px;
}
.item311 {
  position: absolute;
  max-width: initial;
  left: -450px;
  top: -240px;
}
.item313 {
  position: absolute;
  max-width: initial;
  left: -600px;
  top: -240px;
}
.item314 {
  position: absolute;
  max-width: initial;
  left: 0px;
  top: -320px;
}
.item400 {
  position: absolute;
  max-width: initial;
  left: -150px;
  top: -320px;
}
.item401 {
  position: absolute;
  max-width: initial;
  left: -300px;
  top: -320px;
}
.item402 {
  position: absolute;
  max-width: initial;
  left: -450px;
  top: -320px;
}
.item403 {
  position: absolute;
  max-width: initial;
  left: -600px;
  top: -320px;
}
.item411 {
  position: absolute;
  max-width: initial;
  left: 0px;
  top: -400px;
}
.item413 {
  position: absolute;
  max-width: initial;
  left: -150px;
  top: -400px;
}
.item414 {
  position: absolute;
  max-width: initial;
  left: -300px;
  top: -400px;
}
.item000 {
  position: absolute;
  max-width: initial;
  left: -450px;
  top: -400px;
}
